<div>
  <button class = "save-button" data-test="saveGridButton" (click)="saveGrid()">Save Grid</button>
  <div>
    <h3>Saved Grids</h3>
    @for (grid of this.savedGrids; track grid._id) {
      <div>
        <button routerLink="/grid/{{grid._id}}" (click)="loadGrid(grid._id)"  >{{ grid._id }}</button>
      </div>
    }
  </div>
</div>
<div>

<p>Height</p>
<input matInput
  [(ngModel)]="gridHeight"
  type="number"
  (input)="onSizeInput()"
  placeholder="{{gridHeight}}">
<p>Width</p>
<input matInput
  [(ngModel)]="gridWidth"
  type="number"
  (input)="onSizeInput()"
  placeholder="{{gridWidth}}">

  <div [ngStyle]="{'height': (gridHeight * cellSize) + 'px', 'width': (gridWidth * cellSize) + 'px'}"  >
    <mat-grid-list cols="{{gridWidth}}" gridHeight="1:1" gutterSize="0">
      @for (row of gridPackage.grid; track row; let rowIndex = $index) {
        @for (cell of row; track cell; let colIndex = $index) {
          <mat-grid-tile Rowspan="1" Colspan="1">
            <app-grid-cell
            tabindex= "0"
            [gridCell]= "gridPackage.grid[rowIndex][colIndex]"
            (keydown)= "onKeydown($event, currentCol, currentRow)"
            (click)="onClick($event, colIndex, rowIndex)"
            [attr.row]="rowIndex"
            [attr.col]="colIndex"
            [row]="rowIndex"
            [col]="colIndex"
            [grid]="gridPackage.grid"
            [ngStyle]="{'height': cellSize + 'px', 'width': cellSize + 'px'}"
            (gridChange)="onGridChange()"
            ></app-grid-cell>
          </mat-grid-tile>
        }
      }
    </mat-grid-list>
  </div>
  <input matInput
  [(ngModel)]="cellSize"
  type="number"
  placeholder="{{cellSize}}">

<button mat-button (click)="cycleTypingDirection()">Cycle Typing Direction</button>
<p>Current Typing Direction is {{typeDirection}}</p>
