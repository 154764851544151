
<div
  class="cell"
  [ngClass]="{
    'bold-top': gridCell.edges.top,
    'bold-right': gridCell.edges.right,
    'bold-bottom': gridCell.edges.bottom,
    'bold-left': gridCell.edges.left
  }"
>
@if (gridCell.editable) {
  <input matInput
    [(ngModel)]="gridCell.value"
    [ngClass] = "{
      'cell-input': true,
      'blacked-out': gridCell.edges.left && gridCell.edges.bottom && gridCell.edges.top && gridCell.edges.right
    }"
    type="text"
    maxlength="1"
    (input)="onInput(gridCell.value)"
    (keydown)="onKeyDown($event)"
    (click)="onClick($event)"
    placeholder="{{gridCell.value}}">
}
    @else {
<div class="cell-content">{{ gridCell.value }}</div>
}
</div>
